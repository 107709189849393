import { template as template_198c0d608a1047bdabae87d0a3baf4e8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_198c0d608a1047bdabae87d0a3baf4e8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
