import { template as template_ca77ee7b5e684064bd54c4db8a1d15d9 } from "@ember/template-compiler";
const FKText = template_ca77ee7b5e684064bd54c4db8a1d15d9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
