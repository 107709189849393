import { template as template_9b0354cad1c24e7a9ea62b7559adac70 } from "@ember/template-compiler";
const FKLabel = template_9b0354cad1c24e7a9ea62b7559adac70(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
